import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, LinearProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';
// import * as XLSX from 'xlsx'; // Import xlsx library



const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "90vh",
  }
});

const StockAndGIT_Summ = (props) => {

  const classes = useStyles();

  const [StknGITSummDATA, setStknGITSumm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    const storedData = localStorage.getItem('StknGITSummDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setStknGITSumm(parsedData);
      setFilter(parsedData);
      setProgress(100);
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/StkInTransitCcSumm/');
      const data = await response.json();
      setStknGITSumm(data);
      setFilter(data);
      localStorage.setItem('StknGITSummDATA', JSON.stringify(data));
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }
  //----TOTAL----------------------
  let total_StockBales = 0;
  let total_StockValue = 0;
  let total_GITBales = 0;
  let total_Containers = 0;
  let total_GITValue = 0;

  StknGITSummDATA.map((row) => (total_StockBales += row.StockinHand_Qnty));
  StknGITSummDATA.map((row) => (total_StockValue += row.StockinHand_Value));
  StknGITSummDATA.map((row) => (total_GITBales += row.GITBales));
  StknGITSummDATA.map((row) => (total_Containers += row.GITNoOfCont));
  StknGITSummDATA.map((row) => (total_GITValue += row.GITValue));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = StknGITSummDATA.filter((row) => {
      return row.CcDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(StknGITSummDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    //requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="STOCK AND GIT (SUMMARY)" />
      <div style={{ marginTop: "-2.3rem" }}>
        <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => requestSearch("")}
                style={{ display: showClearIcon }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
      </div>
      {loading && <LinearProgressWithLabel value={progress} />}
      {/* <Button onClick={exportToExcel} variant="contained" color="primary">Export to Excel</Button> Add the export button */}

      <TableContainer className='table-container'>
        <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
          <TableHead >
            <TableRow>
              {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
              <StyledTableHeadCell align="left">Description</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Stock Bales</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Stock Value $</StyledTableHeadCell>
              <StyledTableHeadCell align="right">GIT Bales</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Containers</StyledTableHeadCell>
              <StyledTableHeadCell align="right">GIT Value $</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filter.map((row) => (
              <StyledTableRow key={row.Id} hover >
                {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StockAndGIT_Detail/" state={row}>{row.CcDesc}</Button></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.StockinHand_Qnty} decimal={2} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.StockinHand_Value} decimal={2} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.GITBales} decimal={2} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.GITNoOfCont} decimal={2} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.GITValue} decimal={2} /></StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockValue} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Containers} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITValue} decimal={2} /></StyledTableFooterCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default StockAndGIT_Summ

