import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat,LinearProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "68vh",
  }
});

const Cash_Bank_Balance = (props) => {

  const classes = useStyles();

  const [CashDetailDATA, setCashDetail] = useState([]);
  const [BankDetailDATA, setBankDetail] = useState([]);

  const [Cashloading, setCashLoading] = useState(false);
  const [Cashprogress, setCashProgress] = useState(0);

  const [Bankloading, setBankLoading] = useState(false);
  const [Bankprogress, setBankProgress] = useState(0);

  useEffect(() => {
    const storedCashData = localStorage.getItem('CashDetailDATA');
    if (storedCashData) {
      const parsedCashData = JSON.parse(storedCashData);
      setCashDetail(parsedCashData);
      setCashFilter(parsedCashData);
      setCashProgress(100);
      setCashLoading(false);
    } else {
      fetchData();
    }

    const storedBankData = localStorage.getItem('BankDetailDATA');
    if (storedBankData) {
      const parsedBankData = JSON.parse(storedBankData);
      setBankDetail(parsedBankData);
      setBankFilter(parsedBankData);
      setBankProgress(100);
      setBankLoading(false);
    } else {
      fetchData();
    }

  }, []);

  const fetchData = async () => {
    setCashLoading(true);
    setCashProgress(0);

    const updateCashProgress = setInterval(() => {
      setCashProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateCashProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    setBankLoading(true);
    setBankProgress(0);

    const updateBankProgress = setInterval(() => {
      setBankProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateBankProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    try {
      const cashResponse = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/CashDetail/');
      const cashdata = await cashResponse.json();
      setCashDetail(cashdata);
      setCashFilter(cashdata);
      setCashProgress(100);
      localStorage.setItem('CashDetailDATA', JSON.stringify(cashdata));

      const bankResponse = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/BankDetail/');
      const bankdata = await bankResponse.json();
      setBankDetail(bankdata);
      setBankFilter(bankdata);
      setBankProgress(100);
      localStorage.setItem('BankDetailDATA', JSON.stringify(bankdata));
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }  finally {
      clearInterval(updateCashProgress);
      clearInterval(updateBankProgress);
      setCashLoading(false);
      setBankLoading(false);
    }
  }


  // ------------------------- searching------------------------
  const [cashfilter, setCashFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestCashSearch = (searchedVal) => {
    // setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('cashsearchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredCashRows = CashDetailDATA.filter((row) => {
      return row.CashDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setCashFilter(CashDetailDATA)
    }
    else {
      setCashFilter(filteredCashRows)
    }
  };

    // ------------------------- searching------------------------
    const [bankfilter, setBankFilter] = useState([])
    const requestBankSearch = (searchedVal) => {
      // setPage(0);
      setShowClearIcon(searchedVal === "" ? "none" : "flex");
      document.getElementById('banksearchtext').value = searchedVal === "" ? "" : searchedVal;
      const filteredBankRows = BankDetailDATA.filter((row) => {
        return row.bankDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
        setBankFilter(BankDetailDATA)
      }
      else {
        setBankFilter(filteredBankRows)
      }
    };

      //----TOTAL----------------------

  let total_CashValue = 0;
  cashfilter.map((row) => (total_CashValue +=  parseFloat(row.CashBalance)));

  let total_BankValue = 0;
  bankfilter.map((row) => (total_BankValue += parseFloat(row.bankBalance)));

  //----pagination---------------------
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    //requestSearch
    <div>
      <div className='sameLineTable'>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
          <HeaderSM category="" title="CASH BALANCE" />
          <div style={{ marginTop: "-2.3rem" }}>
            <TextField id='cashsearchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestCashSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => requestCashSearch("")}
                    style={{ display: showClearIcon }}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                )
              }}
            />
          </div>
        {Cashloading && <LinearProgressWithLabel value={Cashprogress} />}
        <TableContainer className={classes.container}>
            <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
              <TableHead >
                <TableRow>
                  {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left">Description</StyledTableHeadCell>
                  <StyledTableHeadCell align="right">Balance $</StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cashfilter.map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/GeneralLedger/" state={row}>{row.CashDesc}</Button></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.CashBalance} decimal={2} /></StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_CashValue} decimal={2} /></StyledTableFooterCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className='sameLineTable'>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
          <HeaderSM category="" title="BANK BALANCE" />
          <div style={{ marginTop: "-2.3rem" }}>
            <TextField id='banksearchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestBankSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => requestBankSearch("")}
                    style={{ display: showClearIcon }}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                )
              }}
            />
          </div>
        {Bankloading && <LinearProgressWithLabel value={Bankprogress} />}
        <TableContainer className={classes.container}>
            <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
              <TableHead >
                <TableRow>
                  {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left">Description</StyledTableHeadCell>
                  <StyledTableHeadCell align="right">Balance $</StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankfilter.map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                    <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/GeneralLedger/" state={row}>{row.bankDesc}</Button></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.bankBalance} decimal={2} /></StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_BankValue} decimal={2} /></StyledTableFooterCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>



    </div>
  )
}
export default Cash_Bank_Balance

