import { useState } from 'react';

const useSort = (initialOrder = 'desc', initialOrderBy = 'Item') => {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const sortedData = (data) => {
    if (!orderBy) return data;
    return [...data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    const isNewColumn = orderBy !== property;
    setOrder(isNewColumn ? 'desc' : isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return { order, orderBy, createSortHandler, sortedData };
};

export default useSort;
