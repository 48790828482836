import { React, useState, useEffect } from 'react';
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, LinearProgressWithLabel } from '../components';
import { useLocation, useNavigate, Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { subMonths, toDate } from 'date-fns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../App.css';
import { MdOutlineFilterList } from "react-icons/md";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import useSort from '../components/useSort';
import { Height } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "50vh",
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",

  },

  stickyHeader: {
    position: "sticky",
    left: 0,
    background: "white",
  },




});

const getCellStyle = (SalePer) => {

  if (SalePer >= 90) {
    return { backgroundColor: 'rgb(0, 255, 128)', color: '#000' };
  } else if (SalePer >= 50) {
    return { backgroundColor: 'rgb(0, 255, 255)', color: 'black' };
  } else if (SalePer > 1) {
    return { backgroundColor: 'rgb(255, 128, 0)', color: 'white' };
  } else if (SalePer <= 0) {
    return { backgroundColor: 'rgb(255, 55, 55)', color: 'white' };
  }

};




const { format } = require('date-fns');
let prevfrmdate;
let prevtodate;

const GITEstPnL = (props) => {

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  let showData = true;

  let pRefNo = 0;
  const fRefNo = state ? state.PurhRefNo : null;
  pRefNo = fRefNo ? fRefNo : pRefNo;

  let pRefDate = '';
  const fRefDate = state ? state.PurhRefDate : null;
  pRefDate = fRefDate ? fRefDate : pRefDate;

  let pSpCode = '';
  const fSpCode = state ? state.SpCode : null;
  pSpCode = fSpCode ? fSpCode : pSpCode;

  let pSpName = '';
  const fSpName = state ? state.SpName : null;
  pSpName = fSpName ? fSpName : pSpName;

  let pCcDesc = '';
  const fCcDesc = state ? state.CcDesc : null;
  pCcDesc = fCcDesc ? fCcDesc : pCcDesc;

  let pItemDesc = '';
  const fItemDesc = state ? state.ItemDesc : null;
  pItemDesc = fItemDesc ? fItemDesc : pItemDesc;

  let pPurhContNo = '';
  const fPurhContNo = state ? state.PurhContNo : null;
  pPurhContNo = fPurhContNo ? fPurhContNo : pPurhContNo;

  const [GITEstPnLDATA, setGITEstPnL] = useState([]);
  const [open, setOpen] = useState(false);
  const [frmdate, setfrmDate] = useState(subMonths(new Date(), 12));
  const [todate, settoDate] = useState(new Date());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function getDates() {
    const $frmDate = format(new Date(frmdate), 'yyyy-MM-dd');
    const $toDate = format(new Date(todate), 'yyyy-MM-dd');
    // const $frmDate = $('#frmDate');
    // const $toDate = $('#toDate');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmDate($frmDate);
      settoDate($toDate);
    }
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);
    try {
      console.log(format(new Date(frmdate), 'yyyy-MM-dd'));
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/GITEstPnL?PurhCoID=1&RefNo=' + pRefNo + '&FrmDate=' + format(new Date(frmdate), 'yyyy-MM-dd') + '&AsOnDate=' + format(new Date(todate), 'yyyy-MM-dd') + '&SpCode=' + pSpCode + '&ReportOptn=All&SortBy=Per'); //data source  
      const data = await response.json();
      setGITEstPnL(data);
      setFilter(data);
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }

  if (prevfrmdate !== frmdate || prevtodate !== todate) {
    fetchData();
    prevfrmdate = frmdate;
    prevtodate = todate;
  }

  //----Formulas----------------------
  // let FOB_Value = 0;
  // let Selling_Value = 0;
  // let PnL_Value = 0;
  // let PnL_Per = 0;
  // let Remarks = '';

  let total_GITdQnty = 0;
  let total_FOB_Value = 0;
  let total_GITdDflAmnt = 0;
  let total_SellingValue = 0;
  let total_PnLValue = 0;
  let total_PnL_Per = 0;
  let total_StockInHand = 0;
  let total_Open_Balance = 0;
  let total_Act_QtyIn = 0;
  let total_Act_SaleQtyOut = 0;
  let total_Sale_Per = 0;

  GITEstPnLDATA.map((row) => (total_GITdQnty += row.GITdQnty));
  GITEstPnLDATA.map((row) => (total_FOB_Value += row.FOB_Value));
  GITEstPnLDATA.map((row) => (total_GITdDflAmnt += row.GITdDflAmnt));
  GITEstPnLDATA.map((row) => (total_SellingValue += row.SellingValue));
  GITEstPnLDATA.map((row) => (total_PnLValue += row.PnLValue));
  GITEstPnLDATA.map((row) => (total_StockInHand += row.StockInHand));
  GITEstPnLDATA.map((row) => (total_Open_Balance += row.Open_Balance));
  GITEstPnLDATA.map((row) => (total_Act_QtyIn += row.Act_QtyIn));
  GITEstPnLDATA.map((row) => (total_Act_SaleQtyOut += row.Act_SaleQtyOut));
  total_PnL_Per = total_GITdDflAmnt !== 0 ? (total_PnLValue / total_GITdDflAmnt) * 100 : 0;
  console.log(total_Open_Balance + ' - ' + total_Act_QtyIn + ' - ' + total_Act_SaleQtyOut);
  total_Sale_Per = (total_Open_Balance + total_Act_QtyIn) !== 0 ? (total_Act_SaleQtyOut / (total_Open_Balance + total_Act_QtyIn)) * 100 : 0;

  // GITEstPnLDATA.map((row) => (FOB_Value = row.GITdQnty * row.GITdFOBRate));
  // GITEstPnLDATA.map((row) => (Selling_Value = row.GITdQnty * row.PrListdRate));
  // GITEstPnLDATA.map((row) => (PnL_Value = (row.GITdQnty * row.PrListdRate) - row.GITdDflAmnt));
  // GITEstPnLDATA.map((row) => (PnL_Per = (((row.GITdQnty * row.PrListdRate) - row.GITdDflAmnt) / row.GITdQnty) * 100));
  // GITEstPnLDATA.map((row) => (Remarks = row.Sale_Per >= 90 ? 'Fast Item' :
  //   row.Sale_Per >= 50 ? 'Normal Item' :
  //     row.Sale_Per > 1 ? 'Slow Item' :
  //       row.Sale_Per <= 0 ? 'Dead Item' : ''));

  // ItemPerformDATA.map((row) => (CC_Desc2 = row.Station_02));
  // ItemPerformDATA.map((row) => (CC_Desc3 = row.Station_03));
  // ItemPerformDATA.map((row) => (CC_Desc4 = row.Station_04));
  // ItemPerformDATA.map((row) => (CC_Desc5 = row.Station_05));
  // ItemPerformDATA.map((row) => (CC_Desc6 = row.Station_06));
  // StknGITSummDATA.map((row) => (total_StockValue += row.StockinHand_Value));
  // StknGITSummDATA.map((row) => (total_GITBales += row.GITBales));
  // StknGITSummDATA.map((row) => (total_Containers += row.GITNoOfCont));
  // StknGITSummDATA.map((row) => (total_GITValue += row.GITValue));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = GITEstPnLDATA.filter((row) => {
      return row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(GITEstPnLDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  const { order, orderBy, createSortHandler, sortedData } = useSort('desc', 'Sale_Per');

  // const sortedTableData = sortedData(filter);

  return (
    //requestSearch
    <div>
      <div>
        <div className="set-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">

            <Button onClick={() => { navigate(-1) }} className="hover-underline-animation" style={{ color: 'purple' }}  >List</Button>

            <Button disabled={true}> GIT (ESTIMATED) Profit/Loss </Button>
          </Breadcrumbs>
        </div>
      </div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
        <Header category="" title={"GIT (ESTIMATED) Profit / Loss"}
          OtherInfo={<span>
            <span className="space-right">Station : {pCcDesc}</span>
            <span className="space-right">Brand : {pItemDesc}</span>
            <span className="space-right"> Supplier: {pSpName} </span>
          </span>}
          OtherInfo1={<span>
          </span>}
        />

        <div style={{ marginTop: "-1.5rem" }}>
          {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="From Date"
              autoComplete='off'
              value={frmdate}
              onChange={(newValue) => {
                if (newValue > todate) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    setfrmDate(newValue);

                  }
                }
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"

            />
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <DesktopDatePicker
              label="To Date"
              value={todate}
              onChange={(newValue) => {
                if (frmdate > newValue) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    settoDate(newValue);
                  }
                }
              }}

              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"
            />
          </LocalizationProvider>
          <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
            <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>

          </ButtonGroup>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

          <br />
          <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => requestSearch("")}
                  style={{ display: showClearIcon }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              )
            }}
          />
        </div>
        {loading && <LinearProgressWithLabel value={progress} />}
        <Paper sx={{ width: '100%' }}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="myTable"  >
              <TableHead>
                <TableRow>
                  {/* <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex ${classes.stickyHeader}`}>Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left" className={`stickyHeaderzindex ${classes.sticky}`} sortDirection={orderBy === 'ItemDesc' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'ItemDesc'}
                      direction={orderBy === 'ItemDesc' ? order : 'asc'}
                      onClick={createSortHandler('ItemDesc')}>
                      Item </TableSortLabel></StyledTableHeadCell>
                  <StyledTableHeadCell align="left">
                    <TableSortLabel
                      active={orderBy === 'GdDesc'}
                      direction={orderBy === 'GdDesc' ? order : 'asc'}
                      onClick={createSortHandler('GdDesc')}>
                      Godown </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'GITdQnty'}
                      direction={orderBy === 'GITdQnty' ? order : 'asc'}
                      onClick={createSortHandler('GITdQnty')}>
                      Quantity </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'GITdFOBRate'}
                      direction={orderBy === 'GITdFOBRate' ? order : 'asc'}
                      onClick={createSortHandler('GITdFOBRate')}>
                      FOB Rate </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'FOB_Value'}
                      direction={orderBy === 'FOB_Value' ? order : 'asc'}
                      onClick={createSortHandler('FOB_Value')}>
                      FOB Value </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'GITdDflRate'}
                      direction={orderBy === 'GITdDflRate' ? order : 'asc'}
                      onClick={createSortHandler('GITdDflRate')}>
                      Cost Rate </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'GITdDflAmnt'}
                      direction={orderBy === 'GITdDflAmnt' ? order : 'asc'}
                      onClick={createSortHandler('GITdDflAmnt')}>
                      Cost Value </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'PrListdRate'}
                      direction={orderBy === 'PrListdRate' ? order : 'asc'}
                      onClick={createSortHandler('PrListdRate')}>
                      Selling Rate</TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'SellingValue'}
                      direction={orderBy === 'SellingValue' ? order : 'asc'}
                      onClick={createSortHandler('SellingValue')}>
                      Selling Value </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'PnLValue'}
                      direction={orderBy === 'PnLValue' ? order : 'asc'}
                      onClick={createSortHandler('PnLValue')}>
                      Profit/Loss Value </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'PnL_Per'}
                      direction={orderBy === 'PnL_Per' ? order : 'asc'}
                      onClick={createSortHandler('PnL_Per')}>
                      Profit/Loss% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'StockInHand'}
                      direction={orderBy === 'StockInHand' ? order : 'asc'}
                      onClick={createSortHandler('StockInHand')}>
                      Stock</TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right">
                    <TableSortLabel
                      active={orderBy === 'Sale_Per'}
                      direction={orderBy === 'Sale_Per' ? order : 'asc'}
                      onClick={createSortHandler('Sale_Per')}>
                      Selling%</TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="center">
                    Remarks
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData(filter).map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    <StyledTableCell align="left" className={classes.sticky}>{row.ItemDesc}</StyledTableCell>
                    <StyledTableCell align="left">{row.GdDesc}</StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.GITdQnty} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.GITdFOBRate} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.FOB_Value} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.GITdDflRate} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.GITdDflAmnt} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.PrListdRate} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.SellingValue} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.PnLValue} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.PnL_Per} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.StockInHand} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Sale_Per} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="center" style={getCellStyle(row.Sale_Per)}>{row.Remarks}</StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className={classes.sticky}>Total</StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITdQnty} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_FOB_Value} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITdDflAmnt} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SellingValue} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_PnLValue} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_PnL_Per} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockInHand} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Sale_Per} decimal={2} /></StyledTableFooterCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  )
}
export default GITEstPnL
