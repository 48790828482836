import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat,LinearProgressWithLabel,CircularProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { subMonths, toDate } from 'date-fns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../App.css';
import { MdOutlineFilterList } from "react-icons/md";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "61vh",
  },
});




const { format } = require('date-fns');
let prevfrmdate;
let prevtodate;

const GITList = (props) => {

  const classes = useStyles();


  let showData = true;

  const [GITListDATA, setGITList] = useState([]);
  const [open, setOpen] = useState(false);
  const [frmdate, setfrmDate] = useState(subMonths(new Date(), 12));
  const [todate, settoDate] = useState(new Date());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function getDates() {
    const $frmDate = format(new Date(frmdate), 'yyyy-MM-dd');
    const $toDate = format(new Date(todate), 'yyyy-MM-dd');
    // const $frmDate = $('#frmDate');
    // const $toDate = $('#toDate');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmDate($frmDate);
      settoDate($toDate);
    }
    fetchData();
  }

  useEffect(() => {
    const storedData = localStorage.getItem('GITListDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setGITList(parsedData);
      setFilter(parsedData);
      setProgress(100);
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    try {
      console.log(format(new Date(frmdate), 'yyyy-MM-dd'));
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/GITList'); //data source  
      const data = await response.json();
      setGITList(data);
      setFilter(data);
      localStorage.setItem('GITListDATA', JSON.stringify(data));
      setProgress(100); 
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }

  if (prevfrmdate !== frmdate || prevtodate !== todate) {
    fetchData();
    prevfrmdate = frmdate;
    prevtodate = todate;
  }

  //----TOTAL----------------------
  // let CC_Desc1 = '';
  // let CC_Desc2 = '';
  // let CC_Desc3 = '';
  // let CC_Desc4 = '';
  // let CC_Desc5 = '';
  // let CC_Desc6 = '';

  // ItemPerformSummDATA.map((row) => (CC_Desc1 = row.Station_01));
  // ItemPerformSummDATA.map((row) => (CC_Desc2 = row.Station_02));
  // ItemPerformSummDATA.map((row) => (CC_Desc3 = row.Station_03));
  // ItemPerformSummDATA.map((row) => (CC_Desc4 = row.Station_04));
  // ItemPerformSummDATA.map((row) => (CC_Desc5 = row.Station_05));
  // ItemPerformSummDATA.map((row) => (CC_Desc6 = row.Station_06));

  // StknGITSummDATA.map((row) => (total_StockValue += row.StockinHand_Value));
  // StknGITSummDATA.map((row) => (total_GITBales += row.GITBales));
  // StknGITSummDATA.map((row) => (total_Containers += row.GITNoOfCont));
  // StknGITSummDATA.map((row) => (total_GITValue += row.GITValue));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = GITListDATA.filter((row) => {
      return (row.SpName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
           || row.CcDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
           || row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()));
    });
    if (searchedVal.length < 1) {
      setFilter(GITListDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    //requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="In Process GIT LIST" />

      <div style={{ marginTop: "-1.5rem" }}>
        {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}
{/* 
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From Date"
            autoComplete='off'
            value={frmdate}
            onChange={(newValue) => {
              if (newValue > todate) {
                setError(true);
                setOpen(true);
              }
              else {
                setError(false);
                setOpen(false);
                if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                  setfrmDate(newValue);

                }
              }
            }}
            inputFormat={"DD/MM/YYYY"}
            renderInput={(params) => <TextField {...params} />}
            className="sameLineTabledatetime"
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <DesktopDatePicker
            label="To Date"
            value={todate}
            onChange={(newValue) => {
              if (frmdate > newValue) {
                setError(true);
                setOpen(true);
              }
              else {
                setError(false);
                setOpen(false);
                if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                  settoDate(newValue);
                }
              }
            }}

            inputFormat={"DD/MM/YYYY"}
            renderInput={(params) => <TextField {...params} />}
            className="sameLineTabledatetime"
          />
        </LocalizationProvider>
        <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
          <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>

        </ButtonGroup>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

        <br /> */}
        <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => requestSearch("")}
                style={{ display: showClearIcon }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
      </div>
      {loading && <LinearProgressWithLabel value={progress} />}
      <Paper sx={{ width: '100%' }}>
      <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="myTable"  >
            <TableHead>
              <TableRow>
                {/* <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex ${classes.stickyHeader}`}>Code</StyledTableHeadCell> */}
                <StyledTableHeadCell align="left" >Supplier</StyledTableHeadCell>
                <StyledTableHeadCell align="left">C.C.</StyledTableHeadCell>
                <StyledTableHeadCell align="left">Brand</StyledTableHeadCell>
                <StyledTableHeadCell align="left">GIT Ref. No.</StyledTableHeadCell>
                <StyledTableHeadCell align="left">GIT Ref. Date</StyledTableHeadCell>
                <StyledTableHeadCell align="left">Container #</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filter.map((row) => (
                <StyledTableRow key={row.Id} hover >
                  <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/GITEstPnL/" state={row}>{row.SpName}</Button></StyledTableCell>
                  <StyledTableCell align="left">{row.CcDesc}</StyledTableCell>
                  <StyledTableCell align="left">{row.ItemDesc}</StyledTableCell>
                  <StyledTableCell align="left">{row.PurhRefNo}</StyledTableCell>
                  <StyledTableCell align="left">{row.PurhRefDate}</StyledTableCell>
                  <StyledTableCell align="left">{row.PurhContNo}</StyledTableCell>

               </StyledTableRow>
              ))}
              {/* <StyledTableRow>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockValue} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Containers} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITValue} decimal={2} /></StyledTableFooterCell>
            </StyledTableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
export default GITList

