import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, LinearProgressWithLabel, CircularProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { subMonths, toDate } from 'date-fns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../App.css';
import { MdOutlineFilterList } from "react-icons/md";
import useSort from '../components/useSort';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "55vh", // Adjust based on the space taken by other elements
  },
  // tableContainer: {
  //   maxHeight: 'calc(100vh - 400px)', // Adjust based on the space taken by other elements
  //   overflowY: 'auto',
  // },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",

  },
  sticky1: {
    position: "sticky",
    left: 98,
    background: "white",
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  stickyHeader1: {
    position: "sticky",
    left: 98,
    background: "white",
  }
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& > *': {
    borderBottom: '1px solid black', // Add border to each cell
  },
}));




const { format } = require('date-fns');
let prevfrmdate;
let prevtodate;

const ItemPerformance_Summ = (props) => {

  const classes = useStyles();


  let showData = true;

  const [ItemPerformSummDATA, setItemPerformSumm] = useState([]);
  const [open, setOpen] = useState(false);
  const [frmdate, setfrmDate] = useState(subMonths(new Date(), 12));
  const [todate, settoDate] = useState(new Date());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function getDates() {
    const $frmDate = format(new Date(frmdate), 'yyyy-MM-dd');
    const $toDate = format(new Date(todate), 'yyyy-MM-dd');
    // const $frmDate = $('#frmDate');
    // const $toDate = $('#toDate');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmDate($frmDate);
      settoDate($toDate);
    }
    fetchData();
  }

  useEffect(() => {
    const storedData = localStorage.getItem('ItemPerformSummDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setItemPerformSumm(parsedData);
      setFilter(parsedData);
      setProgress(100);
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    try {
      console.log(format(new Date(frmdate), 'yyyy-MM-dd'));
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/ItemperformanceSumm?FrmDate=' + format(new Date(frmdate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(todate), 'yyyy-MM-dd')); //data source  
      const data = await response.json();
      setItemPerformSumm(data);
      setFilter(data);
      localStorage.setItem('ItemPerformSummDATA', JSON.stringify(data));
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }

  if (prevfrmdate !== frmdate || prevtodate !== todate) {
    fetchData();
    prevfrmdate = frmdate;
    prevtodate = todate;
  }

  //----TOTAL----------------------
  let CC_Desc1 = '';
  let CC_Desc2 = '';
  let CC_Desc3 = '';
  let CC_Desc4 = '';
  let CC_Desc5 = '';
  let CC_Desc6 = '';

  ItemPerformSummDATA.map((row) => (CC_Desc1 = row.Station_01));
  ItemPerformSummDATA.map((row) => (CC_Desc2 = row.Station_02));
  ItemPerformSummDATA.map((row) => (CC_Desc3 = row.Station_03));
  ItemPerformSummDATA.map((row) => (CC_Desc4 = row.Station_04));
  ItemPerformSummDATA.map((row) => (CC_Desc5 = row.Station_05));
  ItemPerformSummDATA.map((row) => (CC_Desc6 = row.Station_06));
  // StknGITSummDATA.map((row) => (total_StockValue += row.StockinHand_Value));
  // StknGITSummDATA.map((row) => (total_GITBales += row.GITBales));
  // StknGITSummDATA.map((row) => (total_Containers += row.GITNoOfCont));
  // StknGITSummDATA.map((row) => (total_GITValue += row.GITValue));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = ItemPerformSummDATA.filter((row) => {
      return row.IMHeadDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(ItemPerformSummDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { order, orderBy, createSortHandler, sortedData } = useSort('asc', 'IMHeadDesc');
  // sortedData(filter);

  return (
    //requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="Item Performance" />

      <div style={{ marginTop: "-1.5rem" }}>
        {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From Date"
            autoComplete='off'
            value={frmdate}
            onChange={(newValue) => {
              if (newValue > todate) {
                setError(true);
                setOpen(true);
              }
              else {
                setError(false);
                setOpen(false);
                if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                  setfrmDate(newValue);

                }
              }
            }}
            inputFormat={"DD/MM/YYYY"}
            renderInput={(params) => <TextField {...params} />}
            className="sameLineTabledatetime"
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <DesktopDatePicker
            label="To Date"
            value={todate}
            onChange={(newValue) => {
              if (frmdate > newValue) {
                setError(true);
                setOpen(true);
              }
              else {
                setError(false);
                setOpen(false);
                if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                  settoDate(newValue);
                }
              }
            }}

            inputFormat={"DD/MM/YYYY"}
            renderInput={(params) => <TextField {...params} />}
            className="sameLineTabledatetime"
          />
        </LocalizationProvider>
        <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
          <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>

        </ButtonGroup>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

        <br />
        <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => requestSearch("")}
                style={{ display: showClearIcon }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
      </div>
      {loading && <LinearProgressWithLabel value={progress} />}
      <Paper sx={{ width: '100%' }}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="myTable"  >
            <TableHead>
              <TableRow>
                <StyledTableHeadCell align="center" className={`stickyHeaderzindex blackrightBorder ${classes.stickyHeader}`}>ItemMaster</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc1}</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc2}</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc3}</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc4}</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc5}</StyledTableHeadCell>
                <StyledTableHeadCell align="center" colSpan={3} className='blackrightBorder'>{CC_Desc6}</StyledTableHeadCell>
              </TableRow>
              <TableRow>
                {/* <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex ${classes.stickyHeader}`}>Code</StyledTableHeadCell> */}
                <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex  blackrightBorder ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={orderBy === 'IMHeadDesc'}
                    direction={orderBy === 'IMHeadDesc' ? order : 'asc'}
                    onClick={createSortHandler('IMHeadDesc')}>
                    Description </TableSortLabel>
                </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_01'}
                    direction={orderBy === 'Margin_Per_01' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_01')}>
                    Margin </TableSortLabel>
                </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_01'}
                    direction={orderBy === 'Stock_01' ? order : 'asc'}
                    onClick={createSortHandler('Stock_01')}>
                    Stock </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_01'}
                    direction={orderBy === 'Selling_Per_01' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_01')}>
                    Selling </TableSortLabel>
                    </StyledTableHeadCell>

                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_02'}
                    direction={orderBy === 'Margin_Per_02' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_02')}>
                    Margin </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_02'}
                    direction={orderBy === 'Stock_02' ? order : 'asc'}
                    onClick={createSortHandler('Stock_02')}>
                    Stock </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_02'}
                    direction={orderBy === 'Selling_Per_02' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_02')}>
                    Selling </TableSortLabel>
                    </StyledTableHeadCell>
                
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_03'}
                    direction={orderBy === 'Margin_Per_03' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_03')}>
                    Margin </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_03'}
                    direction={orderBy === 'Stock_03' ? order : 'asc'}
                    onClick={createSortHandler('Stock_03')}>
                    Stock </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_03'}
                    direction={orderBy === 'Selling_Per_03' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_03')}>
                    Selling </TableSortLabel>
                    </StyledTableHeadCell>

                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_04'}
                    direction={orderBy === 'Margin_Per_04' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_04')}>
                    Margin </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_04'}
                    direction={orderBy === 'Stock_04' ? order : 'asc'}
                    onClick={createSortHandler('Stock_04')}>
                    Stock </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_04'}
                    direction={orderBy === 'Selling_Per_04' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_04')}>
                    Selling </TableSortLabel>
                    </StyledTableHeadCell>

                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_05'}
                    direction={orderBy === 'Margin_Per_05' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_05')}>
                    Margin </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_05'}
                    direction={orderBy === 'Stock_05' ? order : 'asc'}
                    onClick={createSortHandler('Stock_05')}>
                    Stock </TableSortLabel>
                    </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_05'}
                    direction={orderBy === 'Selling_Per_05' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_05')}>
                    Selling </TableSortLabel>
                    </StyledTableHeadCell>

                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Margin_Per_06'}
                    direction={orderBy === 'Margin_Per_06' ? order : 'asc'}
                    onClick={createSortHandler('Margin_Per_06')}>
                    Margin </TableSortLabel>
                </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top'>
                <TableSortLabel
                    active={orderBy === 'Stock_06'}
                    direction={orderBy === 'Stock_06' ? order : 'asc'}
                    onClick={createSortHandler('Stock_06')}>
                    Stock </TableSortLabel>
                </StyledTableHeadCell>
                <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                <TableSortLabel
                    active={orderBy === 'Selling_Per_06'}
                    direction={orderBy === 'Selling_Per_06' ? order : 'asc'}
                    onClick={createSortHandler('Selling_Per_06')}>
                    Selling </TableSortLabel>
                </StyledTableHeadCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData(filter).map((row) => (
                <StyledTableRow key={row.Id} hover >
                  {/* <StyledTableCell align="left"  className={classes.sticky}><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.IMHeadCode}</Button></StyledTableCell> */}
                  <StyledTableCell align="left" className={`blackrightBorder ${classes.sticky}`}><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/ItemPerformance/" state={row}>{row.IMHeadDesc}</Button></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_01} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_01} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_01} decimal={2} /></StyledTableCell>

                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_02} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_02} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_02} decimal={2} /></StyledTableCell>

                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_03} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_03} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_03} decimal={2} /></StyledTableCell>

                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_04} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_04} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_04} decimal={2} /></StyledTableCell>

                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_05} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_05} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_05} decimal={2} /></StyledTableCell>

                  <StyledTableCell align="right"><NumberFormat value={row.Margin_Per_06} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Stock_06} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right" className='blackrightBorder'><NumberFormat value={row.Selling_Per_06} decimal={2} /></StyledTableCell>
                </StyledTableRow>
              ))}
              {/* <StyledTableRow>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_StockValue} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITBales} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Containers} decimal={2} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GITValue} decimal={2} /></StyledTableFooterCell>
            </StyledTableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
export default ItemPerformance_Summ

