 import React from 'react'
import {Link, NavLink,useLocation} from 'react-router-dom';
import {SiShopware} from 'react-icons/si';
import {MdOutlineCancel,MdAnalytics} from 'react-icons/md';
import {FaRecycle} from 'react-icons/fa';
import {GiWaterRecycling} from 'react-icons/gi';
import {BiAnalyse} from 'react-icons/bi';
import {SiKatana,SiAnalogue} from 'react-icons/si';
import {TooltipComponent} from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import { MdAttachMoney ,MdAccountBalance ,MdCollectionsBookmark, MdViewList} from 'react-icons/md';
// import { BiCustomize } from 'react-icons/bi';
import { FaChartBar } from 'react-icons/fa';
import { TfiLayoutListThumbAlt } from 'react-icons/tfi';

// import companyLogo from '../images/companyLogo.png'; Hassan Ne Band Kia Hai

const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'Cash & Bank Balance',
        linkName: 'CASH_BANK_BALANCE',
        // icon: <MdAttachMoney />,
        
      },
      {
        name: 'Sundry Creditors',
        linkName: 'SundryCreditors',
        // icon: <MdCollectionsBookmark />,
      },
      {
        name: 'Consolidated Report',
        linkName: 'BalanceSheet',
        // icon: <MdAccountBalance />,
      },
      {
        name: 'Stock And GIT (Summary)',
        linkName: 'StockAndGIT_Summ',
        // icon: <FaChartBar />,
      } ,
      {
        name: 'Item Performance Analysis',
        linkName: 'ItemPerformance_Summ',
        // icon: <MdViewList />,
      },
      {
        name: 'GIT (Estimated) PnL',
        linkName: 'GITList',
        // icon: <MdViewList />,
      }
    ],
  },
]



const Sidebar = () => {
  let location = useLocation();
  
  const {activeMenu, setActiveMenu, screenSize} = useStateContext();
  const handleCloseSiderBar = () => {
    if(activeMenu && screenSize <= 900){
      setActiveMenu(false);
    }
  }


  const activeLink = 'flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';
  const normalLink = 'flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10" style={{backgroundColor:"#eeee"}}>
      {activeMenu && (<>
      <div className="flex justify-between items-center">
        <Link to="/" onClick={handleCloseSiderBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
          {/* <SiShopware />  */}
          {/* <img src={companyLogo}></img> Hassan Ne Band Kia Hai */}
          <span>Besttex</span>
        </Link>
        <TooltipComponent content="Menu" position="BottomCenter">
          <button type="button" onClick={()=> setActiveMenu((prevActiveMenu) => !prevActiveMenu)} className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
            <MdOutlineCancel />
          </button>
        </TooltipComponent>
      </div>
      <div className="mt-10">
        {links.map((item) => (<div key={item.title}>
          <p className="text-gray-400 m-3 mt-4 uppercase">
          {/* {item.title} */}
          DASHBOARD
          </p>
          {item.links.map((link) => (
            <NavLink to={`/${link.linkName}`}
                     key={link.linkName}
                     onClick={handleCloseSiderBar}
                     style={({ isActive }) => ({
                      background: isActive ?  "linear-gradient(60deg,#ab47bc, #8e24aa)": '',
                      color: isActive ? ' #00000' : '',
                      fontWeight: isActive ? 'bold' : '',
                      boxShadow:"0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
                    })}
                     className={({ isActive }) => (isActive ? activeLink : normalLink)}>

                      {link.icon}
                      <span className="capitalize">{link.name}</span>

            </NavLink>
          ))}
           </div>
        ))}
{/* location.pathname === "/FinancialGL/" || location.pathname === "/FinancialTB/" || location.pathname === "/financials"  */}
     {/*   <NavLink to={`/${'FinancialAnalysis'}`} 
          key={'FinancialAnalysis'}
          onClick={handleCloseSiderBar}
          style={({ isActive }) => ({
           background: (isActive = (["/FinancialAnalysis","/"].includes(location.pathname)) ? true : false ) ?  "linear-gradient(60deg,#ab47bc, #8e24aa)": '',
           color: (isActive = (["/FinancialAnalysis","/"].includes(location.pathname)) ? true : false ) ? ' #00000' : '',
           fontWeight: (isActive = (["/FinancialAnalysis","/"].includes(location.pathname)) ? true : false ) ? 'bold' : '',
           boxShadow:"0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
         })}
         className={({ isActive }) => (isActive = (["/FinancialAnalysis","/"].includes(location.pathname)) ? true : false ) ? activeLink : normalLink}> 
          <MdAccountBalanceWallet />
          <span className="capitalize"> financials</span>
        </NavLink>

       <NavLink to={`/${'ProductionAnalysis'}`} 
          key={'ProductionAnalysis'}
          onClick={handleCloseSiderBar}
          style={({ isActive }) => ({
           background: (isActive = (["/ProductionAnalysis"].includes(location.pathname)) ? true : false ) ?  "linear-gradient(60deg,#ab47bc, #8e24aa)": '',
           color: (isActive = (["/ProductionAnalysis"].includes(location.pathname)) ? true : false ) ? ' #00000' : '',
           fontWeight: (isActive = (["/ProductionAnalysis"].includes(location.pathname)) ? true : false ) ? 'bold' : '',
           boxShadow:"0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
         })}
         className={({ isActive }) => (isActive = (["/ProductionAnalysis"].includes(location.pathname)) ? true : false ) ? activeLink : normalLink}> 
          <MdAccountBalanceWallet />
          <span className="capitalize"> Production (Mix Rags)</span>
        </NavLink>

        <NavLink to={`/${'OrderAnalysis'}`} 
          key={'OrderAnalysis'}
          onClick={handleCloseSiderBar}
          style={({ isActive }) => ({
           background: (isActive = (["/OrderAnalysis"].includes(location.pathname)) ? true : false ) ?  "linear-gradient(60deg,#ab47bc, #8e24aa)": '',
           color: (isActive = (["/OrderAnalysis"].includes(location.pathname)) ? true : false ) ? ' #00000' : '',
           fontWeight: (isActive = (["/OrderAnalysis"].includes(location.pathname)) ? true : false ) ? 'bold' : '',
           boxShadow:"0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
         })}
         className={({ isActive }) => (isActive = (["/OrderAnalysis"].includes(location.pathname)) ? true : false ) ? activeLink : normalLink}> 
          <MdAccountBalanceWallet />
          <span className="capitalize"> Order Analysis</span>
        </NavLink> </div>*/}
          
          
    

      </div>
      </>)}

    </div>
  )
}

export default Sidebar