import React from 'react'
import TableCell from '@mui/material/TableCell';
import { withStyles } from "@material-ui/core/styles";

const StyledTableFooterCell = withStyles({
    root: {
        fontWeight:"bold",
        color:"#5946B2 !important",
        border: '1px solid #ddd',
        padding:'0px 5px !important',
    },
})(TableCell);

export default StyledTableFooterCell