import React from 'react'
import TableCell from '@mui/material/TableCell';
import { withStyles } from "@material-ui/core/styles";

const StyledTableHeadCell = withStyles({
    root: {
      color: "#9c27b0!important",
      fontSize:"0.775rem!important",
      border: '1px solid #ddd',
      padding: '0px 5px !important',
    },
  })(TableCell);

// function StyledTableHeadCell() {
//   return (
//     <div></div>
//   )
// }

export default StyledTableHeadCell