import React from "react";
import * as ReactDOM from "react-dom";
import App from './App';
import './index.css';
import './App.css';
import { ContextProvider } from './contexts/ContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,

  document.getElementById('root'));

  
  function removeItemsWithPrefix(prefix) {
    // Get all keys from localStorage
    const keys = Object.keys(localStorage);
    
    // Remove items that start with the given prefix
    keys.forEach(key => {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    });
  }


window.addEventListener('beforeunload', function () {
  // localStorage.removeItem('StknGITSummDATA');
  removeItemsWithPrefix('CashDetailDATA');
  removeItemsWithPrefix('BankDetailDATA');
  removeItemsWithPrefix('SundryCreditorsDATA');
  removeItemsWithPrefix('BalanceSheetDATA');
  removeItemsWithPrefix('StknGITSummDATA');
  removeItemsWithPrefix('StknGITDetailDATA');
  removeItemsWithPrefix('ItemPerformSummDATA');
  removeItemsWithPrefix('GITListDATA');

  
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
