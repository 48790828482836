import React from 'react'
import TableCell from '@mui/material/TableCell';
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles({
  root: {
    fontSize: "smaller!important",
    border: '1px solid #ddd',
    padding:'0px 5px !important',
  },
})(TableCell);



export default StyledTableCell