import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat,LinearProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: "70vh",
  }
});

const BalanceSheet = (props) => {

  const classes = useStyles();

  const [BSDATA, setBSDATA] = useState([]);
  const [assetsData, setAssetsData] = useState([]);
  const [liabilitiesData, setLiabilitiesData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const storedData = localStorage.getItem('BalanceSheetDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setBSDATA(parsedData);
      filterData(parsedData);
      setProgress(100);
      setLoading(false);
    } else {
      fetchData();
    }

  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    try {
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/balancesheet/');
      const data = await response.json();
      setBSDATA(data);
      filterData(data);
      localStorage.setItem('BalanceSheetDATA', JSON.stringify(data));
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }
  //----filterData----------------------
  const filterData = (data) => {
    const assets = data.filter(row => row.FSFrmNo === 'BS_ASS');
    const liabilities = data.filter(row => row.FSFrmNo === 'BS_LIAB');
    setAssetsData(assets);
    setLiabilitiesData(liabilities);
  }


  //----pagination---------------------
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    //requestSearch
    <div className='balancesheet'>
      <div className='sameLineTable'>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
          <HeaderSM category="" title="Liabilities & Equities"/>
            {loading && <LinearProgressWithLabel value={progress} />}
            <TableContainer className={classes.container}>
            <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
              <TableHead >
                <TableRow>
                  {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left" >Description</StyledTableHeadCell>
                  <StyledTableHeadCell align="right">Value $</StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liabilitiesData.map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                    <StyledTableCell align="left" className={row.FSDsStyl === "B" ? "fontbold" : ""}>{row.FSDesc}</StyledTableCell>
                    <StyledTableCell align="right" className={row.FSDsStyl === "B" ? "fontbold" : ""}><NumberFormat value={row.FSCol1} decimal={2} /></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className='sameLineTable'>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
          <HeaderSM category="" title="Assets" />

            {loading && <LinearProgressWithLabel value={progress} />}
            <TableContainer className={classes.container}>
            <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
              <TableHead >
                <TableRow>
                  {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left">Description</StyledTableHeadCell>
                  <StyledTableHeadCell align="right">Value $</StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assetsData.map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                    <StyledTableCell align="left" className={row.FSDsStyl === "B" ? "fontbold" : ""}>{row.FSDesc}</StyledTableCell>
                    <StyledTableCell align="right"className={row.FSDsStyl === "B" ? "fontbold" : ""}><NumberFormat value={row.FSCol1} decimal={2} /></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>



    </div>
  )
}
export default BalanceSheet

