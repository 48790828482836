import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, LinearProgressWithLabel } from '../components';
import { useLocation, useNavigate, Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { subMonths, toDate } from 'date-fns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../App.css';
import { MdOutlineFilterList } from "react-icons/md";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import useSort from '../components/useSort';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "80vh",
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",

  },
  sticky1: {
    position: "sticky",
    left: 98,
    background: "white",
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  stickyHeader1: {
    position: "sticky",
    left: 98,
    background: "white",
  }
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& > *': {
    borderBottom: '1px solid black', // Add border to each cell
  },
}));

const getCellStyle = (InQty, Stock, SaleQty, SalePer) => {
  if (InQty == 0 && Stock == 0 && SaleQty == 0) {
    return { backgroundColor: 'rgb(211,211,211)', color: '#000' };
  }
  else {
    if (SalePer >= 90) {
      return { backgroundColor: 'rgb(0, 255, 128)', color: '#000' };
    } else if (SalePer >= 50) {
      return { backgroundColor: 'rgb(0, 255, 255)', color: 'black' };
    } else if (SalePer > 0) {
      return { backgroundColor: 'rgb(255, 128, 0)', color: 'white' };
    } else if (SalePer <= 0) {
      return { backgroundColor: 'rgb(255, 55, 55)', color: 'white' };
    }
  }
};




const { format } = require('date-fns');
let prevfrmdate;
let prevtodate;

const ItemPerformance = (props) => {

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  let showData = true;

  let pIMHeadCode = '';
  const fIMHeadCode = state ? state.IMHeadCode : null;
  pIMHeadCode = fIMHeadCode ? fIMHeadCode : pIMHeadCode;

  let pIMHeadDesc = '';
  const fIMHeadDesc = state ? state.IMHeadDesc : null;
  pIMHeadDesc = fIMHeadDesc ? fIMHeadDesc : pIMHeadDesc;

  const [ItemPerformDATA, setItemPerform] = useState([]);
  const [open, setOpen] = useState(false);
  const [frmdate, setfrmDate] = useState(subMonths(new Date(), 12));
  const [todate, settoDate] = useState(new Date());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function getDates() {
    const $frmDate = format(new Date(frmdate), 'yyyy-MM-dd');
    const $toDate = format(new Date(todate), 'yyyy-MM-dd');
    // const $frmDate = $('#frmDate');
    // const $toDate = $('#toDate');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmDate($frmDate);
      settoDate($toDate);
    }
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);
    try {
      console.log(format(new Date(frmdate), 'yyyy-MM-dd'));
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/Itemperformance?IMCoID=1&FrmIMCode=' + pIMHeadCode + '&ToIMCode=' + pIMHeadCode + 'ZZZZZZZZZZ&FrmDate=' + format(new Date(frmdate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(todate), 'yyyy-MM-dd')); //data source  
      const data = await response.json();
      const filteredData = data.filter(item => item.IMCode !== pIMHeadCode);
      setItemPerform(filteredData);
      setFilter(filteredData);
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }

  if (prevfrmdate !== frmdate || prevtodate !== todate) {
    fetchData();
    prevfrmdate = frmdate;
    prevtodate = todate;
  }

  //----TOTAL----------------------
  let CC_Desc1 = '';
  let CC_Desc2 = '';
  let CC_Desc3 = '';
  let CC_Desc4 = '';
  let CC_Desc5 = '';
  let CC_Desc6 = '';

  // Margin total start
  let total_SaleVlu_01 = 0;
  let total_SaleVlu_02 = 0;
  let total_SaleVlu_03 = 0;
  let total_SaleVlu_04 = 0;
  let total_SaleVlu_05 = 0;
  let total_SaleVlu_06 = 0;

  let total_COS_01 = 0;
  let total_COS_02 = 0;
  let total_COS_03 = 0;
  let total_COS_04 = 0;
  let total_COS_05 = 0;
  let total_COS_06 = 0;

  let total_Margin_01 = 0;
  let total_Margin_02 = 0;
  let total_Margin_03 = 0;
  let total_Margin_04 = 0;
  let total_Margin_05 = 0;
  let total_Margin_06 = 0;
  // Margin total end

  // Stock total start
  let total_Stock_01 = 0;
  let total_Stock_02 = 0;
  let total_Stock_03 = 0;
  let total_Stock_04 = 0;
  let total_Stock_05 = 0;
  let total_Stock_06 = 0;
  // Stock total end

  // SalePer total start
  let total_InQty_01 = 0;
  let total_InQty_02 = 0;
  let total_InQty_03 = 0;
  let total_InQty_04 = 0;
  let total_InQty_05 = 0;
  let total_InQty_06 = 0;

  let total_SaleQty_01 = 0;
  let total_SaleQty_02 = 0;
  let total_SaleQty_03 = 0;
  let total_SaleQty_04 = 0;
  let total_SaleQty_05 = 0;
  let total_SaleQty_06 = 0;

  let total_SalePer_01 = 0;
  let total_SalePer_02 = 0;
  let total_SalePer_03 = 0;
  let total_SalePer_04 = 0;
  let total_SalePer_05 = 0;
  let total_SalePer_06 = 0;
  // SalePer total end


  ItemPerformDATA.map((row) => (CC_Desc1 = row.Station_01));
  ItemPerformDATA.map((row) => (CC_Desc2 = row.Station_02));
  ItemPerformDATA.map((row) => (CC_Desc3 = row.Station_03));
  ItemPerformDATA.map((row) => (CC_Desc4 = row.Station_04));
  ItemPerformDATA.map((row) => (CC_Desc5 = row.Station_05));
  ItemPerformDATA.map((row) => (CC_Desc6 = row.Station_06));

  // Margin total start
  ItemPerformDATA.map((row) => (total_SaleVlu_01 += row.SaleVlu_01));
  ItemPerformDATA.map((row) => (total_SaleVlu_02 += row.SaleVlu_02));
  ItemPerformDATA.map((row) => (total_SaleVlu_03 += row.SaleVlu_03));
  ItemPerformDATA.map((row) => (total_SaleVlu_04 += row.SaleVlu_04));
  ItemPerformDATA.map((row) => (total_SaleVlu_05 += row.SaleVlu_05));
  ItemPerformDATA.map((row) => (total_SaleVlu_06 += row.SaleVlu_06));

  ItemPerformDATA.map((row) => (total_COS_01 += row.COS_01));
  ItemPerformDATA.map((row) => (total_COS_02 += row.COS_02));
  ItemPerformDATA.map((row) => (total_COS_03 += row.COS_03));
  ItemPerformDATA.map((row) => (total_COS_04 += row.COS_04));
  ItemPerformDATA.map((row) => (total_COS_05 += row.COS_05));
  ItemPerformDATA.map((row) => (total_COS_06 += row.COS_06));

  total_Margin_01 = total_COS_01 !== 0 ? ((total_SaleVlu_01 - total_COS_01) / total_COS_01) * 100 : 0;
  total_Margin_02 = total_COS_02 !== 0 ? ((total_SaleVlu_02 - total_COS_02) / total_COS_02) * 100 : 0;
  total_Margin_03 = total_COS_03 !== 0 ? ((total_SaleVlu_03 - total_COS_03) / total_COS_03) * 100 : 0;
  total_Margin_04 = total_COS_04 !== 0 ? ((total_SaleVlu_04 - total_COS_04) / total_COS_04) * 100 : 0;
  total_Margin_05 = total_COS_05 !== 0 ? ((total_SaleVlu_05 - total_COS_05) / total_COS_05) * 100 : 0;
  total_Margin_06 = total_COS_06 !== 0 ? ((total_SaleVlu_06 - total_COS_06) / total_COS_06) * 100 : 0;
  // Margin total end

  // Stock total start
  ItemPerformDATA.map((row) => (total_Stock_01 += row.Stock_01));
  ItemPerformDATA.map((row) => (total_Stock_02 += row.Stock_02));
  ItemPerformDATA.map((row) => (total_Stock_03 += row.Stock_03));
  ItemPerformDATA.map((row) => (total_Stock_04 += row.Stock_04));
  ItemPerformDATA.map((row) => (total_Stock_05 += row.Stock_05));
  ItemPerformDATA.map((row) => (total_Stock_06 += row.Stock_06));
  // Stock total end

  // SalePer total start
  ItemPerformDATA.map((row) => (total_InQty_01 += row.InQty_01));
  ItemPerformDATA.map((row) => (total_InQty_02 += row.InQty_02));
  ItemPerformDATA.map((row) => (total_InQty_03 += row.InQty_03));
  ItemPerformDATA.map((row) => (total_InQty_04 += row.InQty_04));
  ItemPerformDATA.map((row) => (total_InQty_05 += row.InQty_05));
  ItemPerformDATA.map((row) => (total_InQty_06 += row.InQty_06));

  ItemPerformDATA.map((row) => (total_SaleQty_01 += row.SaleQty_01));
  ItemPerformDATA.map((row) => (total_SaleQty_02 += row.SaleQty_02));
  ItemPerformDATA.map((row) => (total_SaleQty_03 += row.SaleQty_03));
  ItemPerformDATA.map((row) => (total_SaleQty_04 += row.SaleQty_04));
  ItemPerformDATA.map((row) => (total_SaleQty_05 += row.SaleQty_05));
  ItemPerformDATA.map((row) => (total_SaleQty_06 += row.SaleQty_06));

  total_SalePer_01 = total_InQty_01 !== 0 ? (total_SaleQty_01 / total_InQty_01) * 100 : 0;
  total_SalePer_02 = total_InQty_02 !== 0 ? (total_SaleQty_02 / total_InQty_02) * 100 : 0;
  total_SalePer_03 = total_InQty_03 !== 0 ? (total_SaleQty_03 / total_InQty_03) * 100 : 0;
  total_SalePer_04 = total_InQty_04 !== 0 ? (total_SaleQty_04 / total_InQty_04) * 100 : 0;
  total_SalePer_05 = total_InQty_05 !== 0 ? (total_SaleQty_05 / total_InQty_05) * 100 : 0;
  total_SalePer_06 = total_InQty_06 !== 0 ? (total_SaleQty_06 / total_InQty_06) * 100 : 0;
  // SalePer total end


  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = ItemPerformDATA.filter((row) => {
      return row.IMDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(ItemPerformDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const { order, orderBy, createSortHandler, sortedData } = useSort('asc', 'IMDesc');
  // const sortedTableData = sortedData(filter);

  return (
    //requestSearch
    <div>
      <div>
        <div className="set-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">

            <Button onClick={() => { navigate(-1) }} className="hover-underline-animation" style={{ color: 'purple' }}  >Brands</Button>

            <Button disabled={true}> {pIMHeadDesc} </Button>
          </Breadcrumbs>
        </div>
      </div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
        <HeaderSM category="" title={pIMHeadDesc + " PERFORMANCE"} />

        <div style={{ marginTop: "-1.5rem" }}>
          {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="From Date"
              autoComplete='off'
              value={frmdate}
              onChange={(newValue) => {
                if (newValue > todate) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    setfrmDate(newValue);

                  }
                }
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"
            />
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <DesktopDatePicker
              label="To Date"
              value={todate}
              onChange={(newValue) => {
                if (frmdate > newValue) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    settoDate(newValue);
                  }
                }
              }}

              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"
            />
          </LocalizationProvider>
          <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
            <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>

          </ButtonGroup>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

          <br />
          <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => requestSearch("")}
                  style={{ display: showClearIcon }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              )
            }}
          />
        </div>
        {loading && <LinearProgressWithLabel value={progress} />}
        <Paper sx={{ width: '100%' }}>
          <TableContainer className='table-container'>
            <Table stickyHeader aria-label="sticky table" id="myTable"  >
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell align="center" className={`stickyHeaderzindex blackrightBorder ${classes.stickyHeader}`}>ItemMaster</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc1}</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc2}</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc3}</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc4}</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc5}</StyledTableHeadCell>
                  <StyledTableHeadCell align="center" colSpan={4} className='blackrightBorder'>{CC_Desc6}</StyledTableHeadCell>
                </TableRow>
                <TableRow>
                  {/* <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex ${classes.stickyHeader}`}>Code</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left" className={`sticky-top stickyHeaderzindex  blackrightBorder ${classes.stickyHeader}`}>
                    <TableSortLabel
                      active={orderBy === 'IMDesc'}
                      direction={orderBy === 'IMDesc' ? order : 'asc'}
                      onClick={createSortHandler('IMDesc')}>
                      Description </TableSortLabel>
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_01'}
                      direction={orderBy === 'Margin_01' ? order : 'asc'}
                      onClick={createSortHandler('Margin_01')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_01'}
                      direction={orderBy === 'Stock_01' ? order : 'asc'}
                      onClick={createSortHandler('Stock_01')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'SalePer_01'}
                      direction={orderBy === 'SalePer_01' ? order : 'desc'}
                      onClick={createSortHandler('SalePer_01')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_02'}
                      direction={orderBy === 'Margin_02' ? order : 'asc'}
                      onClick={createSortHandler('Margin_02')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_02'}
                      direction={orderBy === 'Stock_02' ? order : 'asc'}
                      onClick={createSortHandler('Stock_02')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'SalePer_02'}
                      direction={orderBy === 'SalePer_02' ? order : 'asc'}
                      onClick={createSortHandler('SalePer_02')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_03'}
                      direction={orderBy === 'Margin_03' ? order : 'asc'}
                      onClick={createSortHandler('Margin_03')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_03'}
                      direction={orderBy === 'Stock_03' ? order : 'asc'}
                      onClick={createSortHandler('Stock_03')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top '>
                    <TableSortLabel
                      active={orderBy === 'SalePer_03'}
                      direction={orderBy === 'SalePer_03' ? order : 'asc'}
                      onClick={createSortHandler('SalePer_03')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_04'}
                      direction={orderBy === 'Margin_04' ? order : 'asc'}
                      onClick={createSortHandler('Margin_04')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_04'}
                      direction={orderBy === 'Stock_04' ? order : 'asc'}
                      onClick={createSortHandler('Stock_04')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'SalePer_04'}
                      direction={orderBy === 'SalePer_04' ? order : 'asc'}
                      onClick={createSortHandler('SalePer_04')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_05'}
                      direction={orderBy === 'Margin_05' ? order : 'asc'}
                      onClick={createSortHandler('Margin_05')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_05'}
                      direction={orderBy === 'Stock_05' ? order : 'asc'}
                      onClick={createSortHandler('Stock_05')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'SalePer_05'}
                      direction={orderBy === 'SalePer_05' ? order : 'asc'}
                      onClick={createSortHandler('SalePer_05')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Margin_06'}
                      direction={orderBy === 'Margin_06' ? order : 'asc'}
                      onClick={createSortHandler('Margin_06')}>
                      Margin% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'Stock_06'}
                      direction={orderBy === 'Stock_06' ? order : 'asc'}
                      onClick={createSortHandler('Stock_06')}>
                      Stock </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top'>
                    <TableSortLabel
                      active={orderBy === 'SalePer_06'}
                      direction={orderBy === 'SalePer_06' ? order : 'asc'}
                      onClick={createSortHandler('SalePer_06')}>
                      Selling% </TableSortLabel>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="right" className='sticky-top blackrightBorder'>
                    Remarks
                  </StyledTableHeadCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData(filter).map((row) => (
                  <StyledTableRow key={row.Id} hover >
                    {/* <StyledTableCell align="left"  className={classes.sticky}><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.IMCode}</Button></StyledTableCell> */}
                    <StyledTableCell align="left" className={`blackrightBorder ${classes.sticky}`}><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text'>{row.IMDesc}</Button></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Margin_01} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_01} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_01} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_01, row.Stock_01, row.SaleQty_01, row.SalePer_01)}>
                      {row.Remarks_01}
                    </StyledTableCell>

                    <StyledTableCell align="right"><NumberFormat value={row.Margin_02} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_02} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_02} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_02, row.Stock_02, row.SaleQty_02, row.SalePer_02)}>
                      {row.Remarks_02}
                    </StyledTableCell>

                    <StyledTableCell align="right"><NumberFormat value={row.Margin_03} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_03} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_03} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_03, row.Stock_03, row.SaleQty_03, row.SalePer_03)}>
                      {row.Remarks_03}
                    </StyledTableCell>

                    <StyledTableCell align="right"><NumberFormat value={row.Margin_04} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_04} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_04} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_04, row.Stock_04, row.SaleQty_04, row.SalePer_04)}>
                      {row.Remarks_04}
                    </StyledTableCell>

                    <StyledTableCell align="right"><NumberFormat value={row.Margin_05} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_05} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_05} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_05, row.Stock_05, row.SaleQty_05, row.SalePer_05)}>
                      {row.Remarks_05}
                    </StyledTableCell>

                    <StyledTableCell align="right"><NumberFormat value={row.Margin_06} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right"><NumberFormat value={row.Stock_06} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className=''><NumberFormat value={row.SalePer_06} decimal={2} /></StyledTableCell>
                    <StyledTableCell align="right" className='blackrightBorder' style={getCellStyle(row.InQty_06, row.Stock_06, row.SaleQty_06, row.SalePer_06)}>
                      {row.Remarks_06}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className={`blackrightBorder ${classes.sticky}`}>Total</StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_01} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_01} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_01} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_02} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_02} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_02} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_03} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_03} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_03} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_04} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_04} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_04} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_05} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_05} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_05} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Margin_06} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Stock_06} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SalePer_06} decimal={2} /></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }} className='blackrightBorder'></StyledTableFooterCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  )
}
export default ItemPerformance
