import { React, useState, useEffect } from 'react';
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat,LinearProgressWithLabel } from '../components';
import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "62vh",
  }
});

const SundryCreditors = (props) => {

  const classes = useStyles();

  const [SCreditorsDATA, setSCreditorsDATA] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const storedData = localStorage.getItem('SundryCreditorsDATA');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSCreditorsDATA(parsedData);
      setSCreditorfilter(parsedData);
      setProgress(100);
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);

    const updateProgress = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(updateProgress);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 800);

    try {
      const response = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/SuppDetail/');
      const data = await response.json();
      setSCreditorsDATA(data);
      setSCreditorfilter(data);
      localStorage.setItem('SundryCreditorsDATA', JSON.stringify(data));
      setProgress(100);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      clearInterval(updateProgress);
      setLoading(false);
    }
  }
  //----TOTAL----------------------

  let total_SuppPayBalance = 0;
  let total_SuppAdvBalance = 0;
  SCreditorsDATA.map((row) => (total_SuppPayBalance += parseFloat(row.SuppPayBalance),
                                  total_SuppAdvBalance += parseFloat(row.SuppAdvBalance)));


  // ------------------------- searching------------------------
  const [SCreditorfilter, setSCreditorfilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestCashSearch = (searchedVal) => {
    // setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredSCRows = SCreditorsDATA.filter((row) => {
      return row.SuppDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setSCreditorfilter(SCreditorsDATA)
    }
    else {
      setSCreditorfilter(filteredSCRows)
    }
  };



  //----pagination---------------------
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    //requestSearch
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
        <HeaderSM category="" title="Sundry Creditors" />
        <div style={{ marginTop: "-2.3rem" }}>
          <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestCashSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => requestCashSearch("")}
                  style={{ display: showClearIcon }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              )
            }}
          />
        </div>
        {loading && <LinearProgressWithLabel value={progress} />}
        <TableContainer className={classes.container}>
          <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
            <TableHead >
              <TableRow>
                {/* <StyledTableHeadCell align="left">CC Code</StyledTableHeadCell> */}
                <StyledTableHeadCell align="left">Description</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Advance $</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Payable $</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SCreditorfilter.map((row) => (
                <StyledTableRow key={row.Id} hover >
                  {/* <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' component={Link} to="/StkInTransitCcDetail/" state={row}>{row.CcCode}</Button></StyledTableCell> */}
                  <StyledTableCell align="left"><Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text'>{row.SuppDesc}</Button></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.SuppAdvBalance} decimal={2} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.SuppPayBalance} decimal={2} /></StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SuppAdvBalance} decimal={2} /></StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SuppPayBalance} decimal={2} /></StyledTableFooterCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
export default SundryCreditors

